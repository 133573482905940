$navpercentage: 24%;

#all {
	display: grid;
	grid-template-columns: $navpercentage 1fr;

	@media screen and (max-width: 767px) {
		grid-template-columns: 100%;

		nav {
			grid-row: 2; }
		main {
			grid-row: 1; } } }

.sidebar {
	.logo {
		padding-top: 0.8rem;
		padding-bottom: 0.8rem; }
	padding-left: 1.1em;
	padding-right: 1.1em;
	background-color: var(--bgdark);
	border-right: 5px solid var(--accent);

	@media screen and (max-width: 767px) {
  		font-size: 0.8em; }

	&-scrolling {
		width: unset;
		position: unset; }

	&-fixed {
		@media screen and (min-width: 767px) {
			padding-left: 1.1em;
			padding-right: 1.1em;
			position: fixed;
			bottom: 0;
			left: 0;
			width: $navpercentage; } }

	&-menu {
		list-style-type: none;
		padding-left: 0;
		margin-bottom: 40px;

		li {
			font-size: 0.8rem;

			a {
				display: block;
				padding-top: 8px;
				padding-bottom: 8px;
				padding-left: 20px;
				margin: 0;

				color: black;
				font-size: 1.05em;
				border: 1px solid var(--bgdark);

				&:hover {
					border: 1px solid var(--accent);
					/* --randbgurl gets re-generated; see codex.js */
					background: $bglight var(--randbgurl) no-repeat right;

					&:before, a {
						color: $textlink; } } } } }

	hr {
		border-top: 1px solid var(--accent); }

	&-game-info {
		background-color: var(--bgdarker);
		border: 1px solid var(--accent);
		margin-bottom: 1rem;
		overflow: hidden;

		a {
			color: darken($textlink, 5%); }

		img {
			@media (min-width: 768px) {
				width: 100%; } }

		p {
			margin: 0; }

		.sidebar-game-platform {
		    position: absolute;
		    display: inline-block;
		    box-shadow: 0px 0px 10px rgba(0,0,0,0.9);
		    -webkit-transform: rotate(-45deg);
		    -moz-transform: rotate(-45deg);
		    -o-transform: rotate(-45deg);
		    -ms-transform: rotate(-45deg);
		    top: -60px;
		    right: 35px;
		    width: 40px; } }

	&-header {
		font-weight: bold;
		img {
			float: right; } }

	&-heading {
		font-weight: bold;
		font-size: 1.1em;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		padding-top: 10px;
		padding-left: 10px;
		margin-top: 0; }

	&-description {
		color: $textlinkdark;
		font-size: 0.7rem; } }

