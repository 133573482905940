
mark {
  filter: invert(100%); }

#searchbar {
  span {
    padding-bottom: 0.5em; } }

#search {
  margin-bottom: 0.5rem;
  input {
    width: 100%;
    padding: 9px 4px 9px 40px;
    border: 1px solid var(--accent);
    border-radius: 5px;
    background: #efefff url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center; }


  ol {
    border-bottom: 1px solid lightgray;
    padding-top: 1rem; }

  .pagefind-ui__message {
    padding-top: 1rem;
    font-weight: bold; }

  .pagefind-ui__search-clear {
    display: none; }

  .pagefind-ui__result {
    list-style-type: none;
    display: flex;
    align-items: flex-start;
    gap: 40px;
    padding: 30px 0 40px;
    border-top: 1px solid lightgray;

    &-thumb {
      width: 40%;
      max-width: 120px;
      margin-top: 10px;
      aspect-ratio: 3 / 2;
      position: relative;

      img {
        display: block;
        position: absolute;
        font-size: 0;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 200%; } } } }
