nav {
  svg {
    width: 24px;
    height: 24px;
    float: left; } }

%icon-in-text {
  position: relative;
  top: 5px; }

main {
  p {
    .icon {
      @extend %icon-in-text; } }
  li {
    .icon {
      @extend %icon-in-text; } } }


.icon {
  width: 32px;
  height: 32px;
  padding-right: 0.3rem;

  &-small {
    position: relative;
    top: 2px;
    padding-right: 0.1rem;
    width: 16px !important;
    height: 16px !important; }

  &-gray {
    color: #999; }

  &-text {
    @extend %icon-in-text; }

  &-float {
    @extend %icon-in-text;
    float: left; }

  &-inline {
    float: left;
    color: #999;
    width: 24px !important;
    height: 24px !important; } }

a.icon:hover {
    text-decoration: none; }
