* {
	box-sizing: border-box;
	&:before, &:after {
		box-sizing: border-box; } }

html, body {
	overflow-x: hidden; }

header {
	z-index: 1000; }

code {
	font-size: 0.9em;
	padding: 2px;
	border-radius: 5px;
	color: darkred;
	background-color: white; }

.hidden {
	display: none !important; }

.visible-xs {
	display: none !important;

	@media (max-width: 766px) {
		display: block !important; } }

.container-fluid {
	padding-left: 0;
	height: 100%; }

.row {
	margin-right: -15px;
	margin-left: -15px;
	&:before, &:after {
		display: table;
		content: " "; }
	&:after {
		clear: both; } }

.content-column {
	padding-left: 45px;
	padding-right: 45px;
	padding-top: 30px;

	@media (min-width: 1200px) {
		padding-left: 5rem;
		width: 80%; }

	&-content {
		@media (min-width: 1200px) {
			padding: 0; } }

	div.row {
		padding-bottom: 14px; } }


ol, ul {
	margin-top: 0; }

.pull-left {
	float: left !important; }

.box-masonry {
	background-color: var(--bglightshade);
	border: 1px solid var(--accent);
	border-radius: 5px;
	margin-bottom: 30px;
	transition: all 0.2s ease-out;
	display: flex;
	flex-wrap: wrap;

	&-text {
		padding: 15px 20px 15px;
		flex-grow: 1;
		flex-basis: calc(70% - 22px);

		h4 {
			margin: 0; } }

	&-image {
		flex-grow: 1;
		flex-basis: calc(25% - 20px);

		img {
			display: block;
			max-width: 100%;
			min-height: 100%;
			object-fit: cover; } }

	&-description {
		clear: both;
		strong {
			font-size: 1.15rem; } }

	&-date {
		float: right;
		clear: both;
		color: grey;
		font-size: 0.8em;
		padding-top: 0.5em;
		padding-bottom: 0.5em; }

	&:hover {
		box-shadow: 0 0 5px grey; } }

.pagination {
	display: inline-block;
	padding-left: 0;
	border-radius: 4px;

	li {
		list-style-type: none;
		position: relative;
		float: left;
		padding: 6px 12px;
		border: 1px solid var(--bgdark);
		background-color: var(--bgdarker);

		&.active {
			font-weight: bold;
			background-color: var(--bgdark); }

		&.disabled {
			background-color: var(--bglight);
			cursor: not-allowed; } } }
