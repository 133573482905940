
.gamegrid {
	display: grid;
	margin-bottom: 2rem;
	grid-template-columns: repeat(auto-fill, minmax(10em, 1fr));
	column-gap: 1em;
	row-gap: 1.2em;

	h4 {
		margin-bottom: 0; }

	* {
		margin-block-start: 0.6rem; }

	.active {
		.screenshot {
			box-shadow: 0 0 10px grey; } }

	.screenshot {
		max-inline-size: 100%;
		block-size: auto;
		height: 30vh;
		min-width: 100%;
		object-fit: cover; }

	p {
		margin: 0; }

	time {
		display: none; } }

.gamegridcontrols {
	clear: both;
	background: scale-color($bglight, $lightness: -10%);
	padding: 0.7rem;

	button {
		font-size: 0.8em; }

	.sorted {
		box-shadow: 5px 5px 5px 1px rgba(0,0,0,0.35);

		&-ascending:before {
			content: "​ ▼ ​"; }
		&-descending:before {
			content: "​ ▲ ​"; } } }

