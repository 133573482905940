
html {
	font-size: 1.0rem;
	@media (min-width: 960px) {
		font-size: 1.15rem; } }


body, h1, h2, h3, h4, article p, article li, .box-masonry .box-masonry-text p {
	color: $textcolor; }

.copyright {
	color: $textcolor;
	font-size: 0.8rem;
	p.credit a {
		color: $textcolor; } }

.latest-all {
	float: right;
	margin-top: -20px;
	margin-bottom: 20px; }

.logo {
	max-width: 100%;
	height: auto;
	&:hover {
		filter: invert(70%); } }


body {
	background-color: var(--bglight);
	line-height: 1.7;
	margin: 0;
	box-sizing: border-box;
	height: 100%;

	@media (min-width: 960px) {
		font-size: inherit; } }

html body, h1, h2, h3, h4, h5, h6 {
  font-family: $font-stack; }

.meel, a.internal {
  cursor: pointer; }

.support {
  font-size: 1rem;
  margin-bottom: 4rem;
  noscript {
    border-left: 5px solid darkred;
    padding: 0.5rem; } }

.avatar {
  float: right;
  margin-top: 0rem;
  margin-left: 1.5rem;
  img {
    border-radius: 50%; } }



.rfloat {
	float: right; }

.metatitle {
	color: grey;
	font-weight: bold; }

h1 {
	color: lighten($textcolor, 10%);
	font-size: 2.5rem;
	font-weight: 700;
	margin-bottom: 1.6rem;
	margin-top: 0; }

h2 {
	font-size: 2rem;
	font-weight: 700;
	margin-top: 2rem;
	margin-bottom: 1.5m; }

h3 {
	font-size: 1.7rem;
	font-weight: 700;
	margin-top: 2rem;
	margin-bottom: 1.5rem; }

h4 {
	font-size: 1.2rem;
	font-weight: 700; }

ul, ol {
	margin-bottom: 2rem; }


blockquote {
	font-size: 1.1rem;
	font-style: italic;
	border-left: 7px solid var(--accent);
	background-color: var(--bglightshade);
	border-radius: 5px;
	padding: 0.8rem;
	margin: 0 0 24px;

	p {
		margin: 0; } }

p {
  margin: 0 0 24px; }


button {
	display: inline-block;
	padding: 6px 12px;
	font-size: 1rem;
	touch-action: manipulation;
	cursor: pointer;
	font-weight: 400;
	text-align: center;
	background-color: var(--accent);
	color: white;
	border: 1px solid transparent;
	border-color: darkgrey;
	border-radius: 4px;
	transition: all 0.2s ease-out;

	&:hover {
		box-shadow: 0 0 5px grey; } }


.small-navbar button {
	position: fixed;
	z-index: 99;
	margin-top: -1rem;
	margin-left: 1rem; }


hr {
	border: 0;
	border-top: 1px solid var(--accent);
	margin-top: 24px;
	margin-bottom: 24px; }

.img-responsive {
	max-width: 100%;
	height: auto;
	margin: 0 auto;
	display: block; }

#rsslink {
	position: absolute;
	right: 0;
	z-index: 99;

	visibility: hidden;
	@media only screen and (min-width: 1200px) {
		visibility: visible; } }

footer {
	position: fixed;
	bottom: 0;
	right: 0;
	margin: 0;
	height: 140px;

	.img-responsive {
		margin: 0; }

	visibility: hidden;
	@media only screen and (min-width: 1200px) {
		visibility: visible; } }

a {
	&.lbox {
		border-bottom: none;

		&:hover {
			background-color: unset; } }

	&.external {
		background-image: url(/img/external.png);
		background-position: center right;
		background-repeat: no-repeat;
		padding-right: 13px;
		border-bottom: 1px dotted #526A43;

		&-no {
			background: none;
			padding-right: 0; }

		&:hover {
			text-decoration: none; } } }

.footnotes {
	font-size: 0.9em; }


article {
	figure {
		margin: 1.5rem;
		@media (min-width: 992px) {
			margin: 1.5rem 0 1.5rem 0; }

		figcaption {
			padding: 10px;
			text-align: center;
			font-style: italic;
			color: darken(grey, 7%); } }

	img {
		border: 1px solid black;
		border-radius: 5px; }

	.dualscreens {
		@media (min-width: 767px) {
			display: flex;
			grid-column-gap: 10px; }
		margin-top: 2em;
		margin-bottom: 2em;

		figure {
			margin: 0; } }

	.video-mask {
		padding-top: 1.2rem;
		padding-bottom: 1.2rem {
	    width: 100%;
	    border-radius: 5px;
	    overflow: hidden; } }

	a:hover {
		text-decoration: none; }

	a:not([href]) {
		padding-right: 0;
		background-image: none; }

	p, li {
		text-align: left !important; } }

.timeline {
	padding-left: 1rem;
	border-left: 2px dotted gray;

	p strong {
		position: relative;

		&:before {
			position: absolute;
			left: -1.8rem;
			content: "​ ▢ ​";
			background-color: var(--bglight); } } }

main {
	ul li {
		list-style-type: circle; } }

.no-border {
	border: none !important; }

.modal-header {
	display: none; }


a, .link {
	color: $textlink;
	text-decoration: none;
	border-bottom: 1px solid darkgrey; }

a:hover, .link:hover, .active a {
	color: $textlink;
	background-color: #d1d3bd; }

.pages {
	color: var(--accent); }


.categories {
	color: $textlinktag;

	kbd {
		padding: 0.2rem 0.5rem 0.2rem 0.5rem;
		background-color: var(--bgdark);
		border-radius: 5px;
		box-shadow: inset 0 -1px 0 rgba(0,0,0,.25);
		font-size: 90%;
		color: $textlinktag;

		&:hover {
			background-color: $textlinktag;
			color: var(--bgdark); } } }


#skiptocontent {
  a {
    z-index: 99;
    padding: 6px;
    position: absolute;
    top: -50px;
    left: 0px;
    color: white;
    border 1px solid white {}
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    background: black;
    -webkit-transition: top 1s ease-out;
    transition: top 1s ease-out;

    &:focus {
      position: absolute;
      left: 0px;
      top: 0px;
      outline-color: transparent;
      -webkit-transition: top .1s ease-in;
      transition: top .1s ease-in; } } }

.sl-wrapper {
	.sl-counter {
		font-weight: bold; }

	.sl-caption {
		text-align: center;
		background: rgba(0,0,0,0.65) !important; } }

.score {
	border: none;
	height: 1.1em;
	margin-bottom: -4px; }

table {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-collapse: collapse;

  tbody {
    border-top: 1px solid darkgrey;
    tr {
      &:nth-child(odd) {
        background: darken($bglight, 7%); } } } }
