
.toot {
	background: scale-color($bglight, $lightness: +40%);
	border: 1px solid #cccccc;
	padding: 0.5em;
	border-radius: 5px;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

	&:hover {
		border-color: var(--accent);
		box-shadow: rgba(100, 100, 111, 0.2) 5px 17px 29px 5px; } }

.notes {
	.u-photo {
		border-radius: 50%;
		margin-top: 0; } }

.u-comment .avatar {
	margin-top: -55px;

	img {
		width: 60px;
		height: 60px; } }

.tootlist {

	h1, h2, h3 {
		text-align: left; }

	h3 {
		margin-left: 3rem; }

	.toot {
		cursor: pointer;
		margin-bottom: 3rem !important;
		margin-top: 2rem !important;

		.meta {
			border-top: 1px solid #eeeeee;
			padding-top: 0.5rem; } } }
