// can't mix both, but want to "lighten" this one dynamically
$bglight: #f1f4dd;
$textcolor: #363636;
$textlink: #b74a27;
$textlinktag: #5d5c43;
$textlinkdark: #483a07;

html {
	--randbgurl: url(/img/random/1.gif);
	--accent: #a6a57c;
	--bglight: #f1f4dd;
	--bglightshade: #e7ead2;
	--bgdarker: #e6ecb2;
	--bgdark: #d7d7a3; }
